import './bootstrap';
import '../css/app.cms.css';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

AOS.init({
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
});

import VanillaTilt from 'vanilla-tilt';
// [data-tilt]
VanillaTilt.init(document.querySelector("[data-tilt]"), {
    max: 25,
    speed: 400
});

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

console.log("I am cms");
